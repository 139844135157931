/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
$(document).ready(function () {



});
$(document).on('change', '.yesnocheckbox', function () {
    var inputcls = $(this).attr('rel');
    if ($(this).is(':checked'))
    {
        $("#" + inputcls).val('1');
    } else
    {
        $("#" + inputcls).val('0');
    }


});
$(document).on('click', '.change_master', function () {
    $(".mastbtns").removeClass('disabled').addClass("disabled");
    $(".search_master").removeClass('disabled');
    $(".new_master").removeClass('disabled');
    $(".mode_title").html("Change");
});
$(document).on('click', '.search_master', function () {
    $(".mode_title").html("Search");
    $(".pageDivide").removeClass("active");
    $(".pageDivide:first").addClass("active");
    $(".splitPage").trigger("click");
});

$(document).on('click', '.new_master', function () {
    $(".mode_title").html("New");
    $(".mastbtns").removeClass('disabled').addClass("disabled");
    $(".save_master").removeClass('disabled');
    $(".change_master").removeClass('disabled');
    $(".save_master").removeClass('disabled');
});
$(document).on('click', '.view_master', function () {
    $(".mode_title").html("View");
    $(".mastbtns").removeClass('disabled').addClass("disabled");
    $(".edit_master").removeClass('disabled');
    $(".new_master").removeClass('disabled');
    $(".print_master").removeClass('disabled');
    $(".disableOnView").addClass("disabled");
});
$(document).on('click', '.edit_master', function () {
    
        $(".mode_title").html("Edit");
        $(".mastbtns").removeClass('disabled').addClass("disabled");
        $(".save_master").removeClass('disabled');
        $(".change_master").removeClass('disabled');
        $(".disableOnView").removeClass("disabled");
        $(".multiselect_btn").removeClass("disabled");
        $(".disabledAllow").addClass("disabled");
   

});
$(document).on('click', '.pageDivide', function () {
    $(".pageDivide").removeClass("active");
    $(this).addClass("active");
    if ($(this).attr('rel') == '2') // SINGLE
    {
        $(".customPaginationBlock").hide();
        $(this).closest(".content-wrapper").find(".box_contnet2").removeClass("border_left");
    } else  // SPLIT
    {
        $(".customPaginationBlock").show();
        $(this).closest(".content-wrapper").find(".box_contnet2").addClass("border_left");
    }

});
$(document).on('click', '.party_loader', function () {
    $(this).closest('table').find('tr').removeClass("active");
    $(this).closest("tr").removeClass("active").addClass("active");
});
$(document).on('click', '.exit_master', function () {
    window.location.href = "/admin/dashboard";
});
$(document).on('click', '.changevoucher', function () {
     $(".editvoucher").removeClass('utrUpdated');
    $(".vchbtns").removeClass('disabled').addClass("disabled");
    $(".searchvoucher").removeClass('disabled');
    $(".newvoucher").removeClass('disabled');
    $(".approveBill").removeClass('disabled').addClass('disabled');
    $(".rejectBill").removeClass('disabled').addClass('disabled');
    $(".modevoucher").attr("mode", 5);
    $(".modevoucher").html("Change");
    $('.voucherHeaderFrm').find('input, textarea, button, select').removeClass("disabled").addClass("disabled");
    $('.expenseGenralFrm').find('input, textarea, button, select,a').removeClass("disabled").addClass("disabled");
    $(".dateEditFiled").css('background-color', '');
});
$(document).on('click', '.searchvoucher', function () {

    $(".editvoucher").removeClass('utrUpdated');
    $(".savevoucher").removeClass('disabled').addClass('disabled');
    $(".approveBill").removeClass('disabled').addClass('disabled');
    $(".rejectBill").removeClass('disabled').addClass('disabled');
    $(".changevoucher").removeClass('disabled').addClass('disabled');
    $(".searchvoucher").removeClass('disabled');
    $(".modevoucher").attr("mode", 2);
    $(".modevoucher").html("Search");
});

$(document).on('click', '.newvoucher', function () {
    $(".modevoucher").attr("mode", 1);
    $(".modevoucher").html("New");
    $(".vchbtns").removeClass('disabled').addClass("disabled");
    $(".savevoucher").removeClass('disabled');
    $(".savevoucher").removeClass('hidden');
    $(".changevoucher").removeClass('disabled');
    $(".savevoucher").removeClass('disabled');
    $('.voucherHeaderFrm').find('input, textarea, button, select').removeClass("disabled");
    $('.expenseGenralFrm').find('input, textarea, button, select,a').removeClass("disabled");
    $(".dateEditFiled").css('background-color', '#FFFFFF');

});
$(document).on('click', '.viewvoucher', function () {
    $(".savevoucher").removeClass('disabled');
    $(".approveBill").removeClass('disabled').addClass("disabled");
    $(".rejectBill").removeClass('disabled').addClass("disabled");
    $(".modevoucher").attr("mode", 4);
    $(".modevoucher").html("View");
    $(".vchbtns").removeClass('disabled').addClass("disabled");
    if ($("#hiddEditRows").val() == 1) {
        $(".editvoucher").removeClass('disabled');
    }
    if ($("#approveRow").val() == '0') {
        $(".editvoucher").removeClass('disabled').addClass("disabled");
    }

    $(".newvoucher").removeClass('disabled');
    $(".changevoucher").removeClass('disabled');
    $(".dateEditFiled").css('background-color', '');

});
$(document).on('click', '.editvoucher', function () {
    if($(this).hasClass('dateexceed'))
    {
       swal('', "You can not edit this voucher. These voucher dates are restricted.", 'warning');
    
    }else if($(this).hasClass('editNotAllow'))
    {
        swal('', "This is an autovoucher. You can not edit this voucher.", 'warning');
    }else  if($(this).hasClass('utrUpdated'))
    {
       swal('', "UTR updated. You can not edit this Bill.", 'warning'); 
    }
    else
    {
        $(".modevoucher").attr("mode", 3);
        $(".modevoucher").html("Edit");
        $(".vchbtns").removeClass('disabled').addClass("disabled");
        $(".disableOnView").removeAttr("tabindex");
        if ($("#approveRow").val() == '1') {
            var stageId =  $('#stageId').val();
            if(stageId != '1'){
            $(".savevoucher").removeClass('hidden').addClass('hidden');
             $(".approveBill").removeClass('disabled');
            $(".rejectBill").removeClass('disabled');
        }
        } else {
            $(".savevoucher").removeClass('hidden');
        }
        $(".savevoucher").removeClass('disabled');
        $(".changevoucher").removeClass('disabled');
        $(".disableOnView").removeClass("disabled");
        $(".disabledAllow").removeClass("disabled").addClass("disabled");
        $('.disableBudget').prop('disabled', false);

        $(".dateEditFiled").css('background-color', '#FFFFFF');
        $(".dateEditFiledH").css('background-color', '');
       // $("#delete_upload_img").removeClass('disabled');

    }
});
$(document).on('click', '.exitvoucher', function () {
    window.location.href = "/admin/dashboard";
});
/*
 var isCtrlS = false;
 $(document).keyup(function (e) {
 //console.log(e.which);
 if (e.which == 18)
 isCtrlS = false;
 }).keydown(function (e) {
 if (e.which == 18)
 isCtrlS = true;
 if (e.which == 83 && isCtrlS == true) {
 if (!$(".savevoucher").hasClass("disabled")) {
 $(".savevoucher").click();
 return false;
 }
 }
 if (e.which == 67 && isCtrlS == true) {
 if (!$(".changevoucher").hasClass("disabled")) {
 $(".changevoucher").click();
 return false;
 }
 }
 if (e.which == 88 && isCtrlS == true) {
 if (!$(".exitvoucher").hasClass("disabled")) {
 $(".exitvoucher").click();
 return false;
 }
 }
 if (e.which == 78 && isCtrlS == true) {
 if (!$(".newvoucher").hasClass("disabled")) {
 $(".newvoucher").click();
 return false;
 }
 }
 });
 */
function outstandingModal(coaid, slid, trind, _token, vtype)
{
    $.ajax({
        type: "POST",
        url: "/admin/voucher/ajaxpost",
        data: {action_type: "os_window", _token: _token, coaid: coaid, slid: slid, trind: trind, vtype: vtype},
        dataType: 'json',
        success: function (res) {
            $(".os_data_view").html(res.data);
            $("#os_window_popup").modal("show");
        }
    }); //ajax
}
$(document).on('click', '.pickosdata', function (e) {
    var rowid = $(this).attr('rowid');
    var vchrType = $(this).attr('vocherType');
    var costCentersOs = $(this).closest('tr').find('.costCentersOs').attr('ccvalue');
    var reftype = $(this).closest('tr').find('.refTypeOs').attr('reftype');
    var refNumber = $(this).closest('tr').find('.refNumberOs').html();
    var refDt = $(this).closest('tr').find('.refDtOs').attr('refdt');
    var refDtF = $(this).closest('tr').find('.refDtF').val();
    var dr_cr = $(this).closest('tr').find('.dr_cr').attr('refdrcr');
    var trind = '.tr_' + $(this).closest('tr').find('.hiddtrindex').val();
    var balance_amt = $(this).closest('tr').find('.balance_amt').html();
    $(trind).find(".costCenters").find('select[name="costCenters[]"]').val(costCentersOs);
    $(trind).find(".refType").find('select[name="refType[]"]').val(reftype);
    $(trind).find(".refNumber").find('input[name="refNumber[]"]').val(refNumber);
    $(trind).find(".refDt").find('input[name="refDate[]"]').val(refDt);
    $(trind).find(".refDt").find('input[name="refFDate[]"]').val(refDtF);
    if (vchrType == 'JV' || vchrType == 'CV') {
        if (dr_cr == '1')
        {
            $(trind).find(".crAmount").find('input[name="crAmount[]"]').val(balance_amt);
            $(trind).find(".drAmount").find('input[name="drAmount[]"]').val('0.00');
        } else
        {
            $(trind).find(".drAmount").find('input[name="drAmount[]"]').val(balance_amt);
            $(trind).find(".crAmount").find('input[name="crAmount[]"]').val('0.00');
        }
    }
    if (vchrType == 'JV' || vchrType == 'CV') {
        $(".totaldramt").text(calculateTotals('dramtfld'));
        $(".totalcramt").text(calculateTotals('cramtfld'));
        updateNetAmount();
    } else if (vchrType == 'RV') {
        $(trind).find(".crAmount").find('input[name="crAmount[]"]').val(balance_amt);
        $(".totaldramt").text(calculateTotals('amountDr'));
        $(".totalcramt").text(calculateTotals('cramtfld'));
        updateRVNetAmount();
    } else if (vchrType == 'PV') {
        $(trind).find(".drAmount").find('input[name="drAmount[]"]').val(balance_amt);
        $(".totalcramt").text(calculateTotals('payment_amount'));
        $(".totaldramt").text(calculateTotals('dramtfld'));
        updatePVNetAmount();
    } else {
        $(".totaldramt").text(calculateTotals('dramtfld'));
        $(".totalcramt").text(calculateTotals('cramtfld'));
    }
    removeAllValidations();
});
function removeAllValidations()
{
    $('#trans_type').removeClass("has-error");
    $('#voucher_date').removeClass("has-error");
    $(".hideError").closest("div").find(".errorDiv").remove();
    $(".hideErrorTd").removeClass("has-error");
    $(".totalSum").removeClass("error");

    hideTopMessage();
}



$(document).on('click', '.addanotherrow', function () {
    var coaid = $(this).closest('tr').find('.coaHeads').find('.global_select_head').val();
    var slid = $(this).closest('tr').find('.subLedgers').find('select[name="subLedgers[]"] option:selected').val();
    var refid = $(this).closest('tr').find('.refType').find('select[name="refType[]"] option:selected').val();
    var refdt = $(this).closest('tr').find('.refDt').find('input').val();
    var refNum = $(this).closest('tr').find('.refNumber').find('input').val();
    var drv = $(this).closest('tr').find('.drAmount').find('input[name="drAmount[]"]').val();
    var crv = $(this).closest('tr').find('.crAmount').find('input[name="crAmount[]"]').val();
    var amtValid = true;
    if ((drv == '' || typeof drv == undefined || drv == '0' || drv == '0.00') && (crv == '' || typeof crv == undefined || crv == '0' || crv == '0.00'))
    {

        amtValid = false;

    }



    var coasltype = $(this).closest('tr').find('.coaHeads').find('.global_select_head option:selected').attr('sltype');
    if (coasltype == '3')
    {
        slid = 1;
    }
    //if (refNum != '' && coaid != '' && (slid != '' && slid != '0') && refid != '' && refdt != '' && (dramt != cramt))

    if (refNum != '' && coaid != '' && (slid != '' && slid != '0') && refid != '' && refdt != '' && amtValid == true)
    {

        $("#voucherTdRows").find(".addanotherrow").addClass("deleteVoucherRow").addClass("btn-danger");
        $("#voucherTdRows").find(".deleteVoucherRow").removeClass("addanotherrow").removeClass("btn-info").removeClass("btn-danger");
        $("#voucherTdRows").find(".deleteVoucherRow").addClass("btn-danger");
        $("#voucherTdRows").find(".deleteVoucherRow").find('.faicons').removeClass("fa-plus").addClass("fa-trash");
        $("#voucherTdRows").find(".deleteVoucherRow").attr("defId", 0);
        //$("#voucherTdRows").find(".deleteVoucherRow").attr("title",'Delete');
        var newRow = '<tr>' + $("#primaryTr").html() + '</tr>';
        $("#voucherTdRows").append(newRow);
        $("#voucherDetailsTbl").tableHeadFixer({"head": true, 'z-index': 1});
        numBox();
    } else
    {
        $(".savevoucher").click();
    }

});
function updateNetAmount() {
    var totDrAmt = $(".totaldramt").html();
    if (totDrAmt == "") {
        totDrAmt = 0;
    }
    var totCrAmt = $(".totalcramt").html();
    if (totCrAmt == '') {
        totCrAmt = 0;
    }
    var totalNet = parseFloat(totDrAmt) - parseFloat(totCrAmt);
    if (totalNet % 1 == 0) {
        $(".totalnetOff").html(totalNet);
    } else {
        $(".totalnetOff").html(precise_round(totalNet, 2));

    }
}
function updatePVNetAmount() {
    var drAmt = $(".totaldramt").html();
    var totCrAmt = $(".payment_amount").val();
    var tdsAmt = $(".totalTdsAmt").html();
    if (drAmt == '')
    {
        drAmt = 0;
    }
    if (totCrAmt == '')
    {
        totCrAmt = 0;
    }
    if (tdsAmt == '')
    {
        tdsAmt = 0;
    }
    var totDrAmt = parseFloat(drAmt) + parseFloat(tdsAmt);
    var totalNet = parseFloat(totDrAmt) - parseFloat(totCrAmt);
    if (totalNet % 1 == 0) {
        $(".totalnetOff").html(totalNet);
    } else {
        $(".totalnetOff").html(precise_round(totalNet, 2));

    }
}
function updateRVNetAmount() {
    var totDrAmt = $(".amountDr").val();
    var totCrAmt = $(".totalcramt").html();
    var tdsAmt = $(".totalTdsAmt").html();
    if (totDrAmt == '')
    {
        totDrAmt = 0;
    }
    if (totCrAmt == '')
    {
        totCrAmt = 0;
    }
    if (tdsAmt == '')
    {
        tdsAmt = 0;
    }
    var totCrAmt = parseFloat(totCrAmt) + parseFloat(tdsAmt);
    var totalNet = parseFloat(totDrAmt) - parseFloat(totCrAmt);
    if (totalNet % 1 == 0) {
        $(".totalnetOff").html(totalNet);
    } else {
        $(".totalnetOff").html(precise_round(totalNet, 2));

    }
}

$(document).on('change', '.slcombo', function () {
    var sltype = $(this).closest('tr').find('.coaHeads').find('.global_select_head option:selected').attr('sltype');

    if (sltype == '1' && $(this).val() != '0')
    {
        $("#message_span").html('Press ALT+O for outstanding entries.');
    } else
    {
        $("#message_span").html("");
    }
});

$(document).on('focusout', '.slcombo', function () {

    $("#message_span").html("");
});
$(document).on('focus', '.slcombo', function () {
    var sltype = $(this).closest('tr').find('.coaHeads').find('.global_select_head option:selected').attr('sltype');

    if (sltype == '1' && $(this).val() != '0')
    {
        $("#message_span").html('Press ALT+O for outstanding entries.');
    } else
    {
        $("#message_span").html("");
    }
});

// budget em excel start

$(document).on('click', '.excel_reportd', function () {
    var tableId = $(this).attr('tableId');
    var tfootId = $(this).attr('tfootId');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = excelReportData(tableId, tfootId); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', 'budget_em_reports_' + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function excelReportData(tblid, tfootId)
{
    //alert('dft')
    var xldata = $("#em_xl").html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}
// budget em excel end

// ledger subledger excel start

$(document).on('click', '.excel_reportdt', function () {
    //$('.hideTotalTest').hide();
    var name = $(this).attr('report_name');
    //var name="ledger_reports_";
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = excelReport(); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', name + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function excelReport()
{
    //alert('dft')
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: $("#xlBody").html()}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}

// ledger subledger excel end

// em monthly excel start

$(document).on('click', '#excel_reportdM', function () {
    var tableId = $(this).attr('tableId');
    var tfootId = $(this).attr('fotId');
//    if(tfoot != ""){
//        var tfootId = tfoot;
//    }else{
//        var tfootId = "";
//    }
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = excelReportMonthly(tableId, tfootId); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', 'Budget_EM_Month_Wise_Report_' + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function excelReportMonthly(tblid, tfId)
{

    var xldata = $("#monthly_xl").html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}
// em monthly excel end

// doc excel start

$(document).on('click', '.documentExcel', function () {
    //alert($("#voucherNo").val());
    //alert('d');
    //$('.hideTotalTest').hide();
    
    var tablei = $(this).attr('table1');
    var tableii = $(this).attr('table2');
    var tableiii = $(this).attr('table3');
    var reName1 = $(this).attr('report_name');
    var reName = '';
    if(reName1 != 'd'){
       reName = reName1;
    }else{
        reName = $("#voucherNo").val();
    }
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = documentExcelReport(tablei, tableii, tableiii); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName+'_'+ todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function documentExcelReport(tablei, tableii, tableiii)
{
//        $('#'+tableiii).find("tfoot").remove();
//$('#'+tableiii).find("tbody").append($('#hiddeTfoot').html());
//$('#'+tableiii).find("#voucherDetailsTbl").attr("id","vc_xl");
    //var xldata =   $("#vc_xl").html(); 
    //alert(xldata);
    var xldata = '';
    var dataCheck = $("#vc_xl").html();
    if (typeof dataCheck != 'undefined') {
        xldata = dataCheck;
    } else {
        xldata = $('#' + tableiii).html();
    }
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#" + tablei).html() + $('#' + tableii).html() + xldata)}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}

// doc excel end

// annual excel


$(document).on('click', '.annualExcel', function () {
    var table1 = $(this).attr('table1');
    var table2 = $(this).attr('table2');
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = balexcelReportAnnual(table1, table2); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function excelReportAnnual(table1, table2)
{
    //alert('dft')
    var dataCheck1 = '';
    var dataCheck = $('#' + table2).html();
    if (typeof dataCheck != 'undefined') {
        dataCheck1 = dataCheck;
    }

    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#" + table1).html() + dataCheck1)}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}



$(document).on('click', '#excel_schedule', function () {
    var todaysDate = moment().format('DD-MM-YYYY');
     var reName = $(this).attr('report_name');
    var blobURL = excelReportSch(); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function excelReportSch()
{

    var xldata = $("#sch_xl").html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}

$(document).on('click', '.bal_annualExcel', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var table2 = $(this).attr('table2');
    var todaysDate = moment().format('DD-MM-YYYY');
    var reName = $(this).attr('report_name');
    var blobURL = balexcelReportAnnual(table1, table2); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function balexcelReportAnnual(table1, table2)
{
    var table1 = $('#table1').html();
    var table2 = $('#table2').html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: (table1 + table2)}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}

$(document).on('click', '.trialExcel', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var todaysDate = moment().format('DD-MM-YYYY');
    var reName = $(this).attr('report_name');
    var blobURL = trialexcelReportAnnual(table1); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function trialexcelReportAnnual(table1)
{
    // var table1 = $('#table1').html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#" + table1).html())}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}
$(document).on('click', '.trialExcelLedger', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = trialexcelReportLedger(table1); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', 'ledger_reports_' + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function trialexcelReportLedger(table1)
{
    // var table1 = $('#table1').html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#" + table1).html())}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}




$(document).on('click', '.allBillsExcel', function () {
    //alert('d');
    //$('.hideTotalTest').hide();
    var tablei = $(this).attr('table1');
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = allBillsExcelReport(tablei); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function allBillsExcelReport(tablei)
{
    var xldata = $("#sch_xl").html();
    // console.log(xldata); 
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}


$(document).on('click', '.bill_documentExcel', function () {
    //alert('d');
    //$('.hideTotalTest').hide();
    //var tablei = '';
    var tableii = $(this).attr('table1');
    var tableiii = $(this).attr('table3');
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = billDocumentExcelReport(tableii, tableiii); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function billDocumentExcelReport(tableii, tableiii)
{
//        $('#'+tableiii).find("tfoot").remove();
//$('#'+tableiii).find("tbody").append($('#hiddeTfoot').html());
//$('#'+tableiii).find("#voucherDetailsTbl").attr("id","vc_xl");
    //var xldata =   $("#vc_xl").html(); 
    //alert(xldata);
    var xldata = '';
    var dataCheck = $("#vc_xl").html();
    if (typeof dataCheck != 'undefined') {
        xldata = dataCheck;
    } else {
        xldata = $('#' + tableiii).html();
    }
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($('#' + tableii).html() + xldata)}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}


$(document).on('click', '.allPfmsExcel', function () {
    //alert('d');
    //$('.hideTotalTest').hide();
    var tablei = $(this).attr('table1');
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = allBillsExcelReport(tablei); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function allBillsExcelReport(tablei)
{
    //  var xldata =   $("#sch_xl").html(); 
    // console.log(xldata); 
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: $('#' + tablei).html()}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}
$(document).on('click', '.allPfmsExcel1', function () {
    //alert('d');
    //$('.hideTotalTest').hide();
    var tablei = $(this).attr('table1');
    var tableii = $(this).attr('table2');
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = allBillsExcelReports(tablei,tableii); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function allBillsExcelReports(tablei,tableii)
{
    //  var xldata =   $("#sch_xl").html(); 
    // console.log(xldata); 
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table:($("#" + tablei).html() + $('#' + tableii).html())}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}



$(document).on('click', '.excel_worksheet', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = worksheetexcelReportAnnual(table1); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', 'worksheet_reports_' + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function worksheetexcelReportAnnual(table1)
{
    // var table1 = $('#table1').html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#" + table1).html())}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}

$(document).on('click', '.allPfmsBillsExcel', function () {
    var todaysDate = moment().format('DD-MM-YYYY');
    var reName = $(this).attr('report_name');
    var blobURL = excelReportPfmsBudReport(); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function excelReportPfmsBudReport()
{

    var xldata = $("#pfms_bud_xl").html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}


$(document).on('click', '.os_excel_reportd', function () {
    var tablei = $(this).attr('tableId1');
    var tableii = $(this).attr('tableId2');
    var tfootId = $(this).attr('tfootId');
    var todaysDate = moment().format('DD-MM-YYYY');
    var reName = $(this).attr('report_name');
    var blobURL = osExcelReportData(tablei,tableii, tfootId); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function osExcelReportData(tablei,tableii, tfootId)
{
    //alert('dft')
    var xldata = $("#em_xl").html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($('#' + tableii).html())}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}


$(document).on('click', '.adMasterReportExcel', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = adMasterExcel(table1); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function adMasterExcel(table1)
{
     var xldata = $("#ad_xl").html();
    // var table1 = $('#table1').html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}



//function menuRolePermission(cls, _token) {
//    var menuId = $("." + cls).attr('rel');
//    $.ajax({
//        type: "POST",
//        url: "/admin/users/ajaxpost",
//        data: {action_type: "menu_permission", _token: _token, menuId: menuId},
//        dataType: 'json',
//        success: function (res) {
//            //console.log(res.add);
//            if (res.add == '1') {
//                $('.menu_new').removeClass('disabledMenu').addClass('disabledMenu');
//                $('.menu_edit').removeClass('disabledMenu').addClass('disabledMenu');
//                $('.menu_save').removeClass('disabledMenu').addClass('disabledMenu');
//
//                $(".search_master").trigger('click');
//
//            } else {
//                $(".new_master").trigger('click');
//                $('.menu_new').removeClass('disabledMenu');
//                  $('.menu_edit').removeClass('disabledMenu');
//                  $('.menu_save').removeClass('disabledMenu');
//                  
//                
//            }
//            if (res.edit != '1') {
//                $('.menu_edit').removeClass('disabledMenu').addClass('disabledMenu');
//
//            } else {
//                $('.menu_edit').removeClass('disabledMenu');
//            }
//            if (res.delete != '1') {
//                $('.menu_delete').removeClass('disabledMenu').addClass('disabledMenu');
//
//            } else {
//                $('.menu_delete').removeClass('disabledMenu');
//            }
//            if (res.print != '1') {
//                $('.menu_print').removeClass('disabledMenu').addClass('disabledMenu');
//
//            } else {
//                $('.menu_print').removeClass('disabledMenu');
//            }
//        }
//    }); //ajax
//}


$(document).on('click', '.allBillsExcels', function () {
    //alert('d');
    //$('.hideTotalTest').hide();
   
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = allBillsExcelReports(); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function allBillsExcelReports()
{
    var xldata = $("#sch_xls").html();
    // console.log(xldata); 
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}





$(document).on('click', '.PSM_Temp_Excel', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var todaysDate = moment().format('DD-MM-YYYY');
    var reName = $(this).attr('report_name');
    var blobURL = psmTempExcel(table1); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function psmTempExcel(table1)
{
     var xldata = $("#gnrl_xl").html();
   // var table1 = $('#table1').html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata }
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}


$(document).on('click', '.excel_inst_summarised', function () {
    //alert('df')
    var table1 = $(this).attr('table1');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = instexcelReportAnnual(table1); //tableToExcel('employees', 'Sheet1');
    $(this).attr('download', 'institute_summarised_' + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function instexcelReportAnnual(table1)
{
    // var table1 = $('#table1').html();
    var xldata = $("#ad_xl").html();
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    //var ctx = {worksheet: "ICMR" || 'Worksheet', table: ($("#"+tblid).html())}
    var ctx = {worksheet: "ICMR" || 'Worksheet', table: xldata}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}
  $(document).on('click', '.opbal_xl', function () {
    //alert('d');
    //$('.hideTotalTest').hide();
    var tablei = $(this).attr('table1');
  //  alert(tablei);
    var reName = $(this).attr('report_name');
    var todaysDate = moment().format('DD-MM-YYYY');
    var blobURL = opBalExcelReport(tablei); //tableToExcel('employees', 'Sheet1');
    //alert($('.' + tablei).html())
    $(this).attr('download', reName + todaysDate + '.xls')
    $(this).attr('href', blobURL);
});


function opBalExcelReport(tablei)
{
    //alert($('#' + tablei).html())
    //  var xldata =   $("#sch_xl").html(); 
    // console.log(xldata); 
    var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
    , format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }

    //alert(table)

    var ctx = {worksheet: "ICMR" || 'Worksheet', table: $('.' + tablei).html()}
    var blob = new Blob([format(template, ctx)]);
    var blobURL = window.URL.createObjectURL(blob);
    return blobURL;

}
